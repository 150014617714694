<template>
  <div class="categories">
    <div
      class="categories__handler"
    >
      <p
        @click="toggleShow"
        class="categories__heading weight-500 pointer between-md row m0 cl-gray fs-medium underline"
      >
        {{ $t('Filter by categories') }}
        <i class="nav-arrow ml5 flex middle-xs" :class="show ? 'icon-chevron-up' : 'icon-chevron-down'" />
      </p>
      <div
        class="mt10 row mx0"
        v-if="show"
      >
        <button
          v-for="category in categories"
          :key="category.category_id"
          class="categories__button no-outline bg-cl-transparent py10 px20"
          :class="{ 'categories__button--active': value.includes(category.category_id) }"
          type="button"
          @click="toggleCategory(category)"
        >
          {{ category.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    toggleCategory (category) {
      const isSelected = this.value.includes(category.category_id)
      if (isSelected) {
        this.$emit('input', this.value.filter(categoryId => categoryId !== category.category_id))
      } else {
        this.$emit('input', [...this.value, category.category_id])
      }
    },
    toggleShow () {
      this.show = !this.show
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/variables/colors";
$break-large: 1030px;

.categories {
  &__heading {
    width: 100%;
  }

  &__handler {
    display: flex;
    flex-wrap: wrap;
  }

  &__button {
    background-color: #fff;
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 15px;
    border: none;
    transition: all 0.2s ease;
    box-shadow: 0 1px 4px #00000029;
    &--active {
      background: #333;
      color: #fff;
    }

    &--active {
      text-decoration: underline;
    }
    @media screen and (min-width: $break-large) {
      &:hover{
        background: #333;
        color: #fff;
      }
    }
  }

  &__button-text {
    display: block;
    margin-right: 15px;
  }

}
i.up {
  transform: rotate(180deg);
}

</style>
